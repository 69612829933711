<template>
    <div class="video-row" :style="{'--custom-colour': colour }">
        <div class="video-heading-container">
			<p class="video-row-title" v-if="!isEditing">{{ collection.name }}</p>
			<input class="video-row-title" v-else v-model="nameEdit">
            <div class="controls collection" v-if="isAdmin">
				<button class="control-button material-icons" @click="edit">{{ isEditing ? 'done' : 'edit'}}</button>
				<button class="control-button material-icons" @click="pickColour">{{ showPicker ? 'done' : 'palette'}}</button>
				<button class="control-button material-icons" @click="remove">delete</button>
				<button class="control-button material-icons" @click="add">add</button>
				<button class="control-button material-icons" @click="prev">arrow_back</button>
				<button class="control-button material-icons" @click="next">arrow_forward</button>
				<chrome :value="colour" v-if="showPicker" @input="changeColour"></chrome>
			</div>
        </div>

        <div class="video-scroll">
            <div class="video-card" v-for="(subCollection, index) in subCollectionsForCollection()" v-bind:key="index" @click="$emit('select', { subCollection: subCollection, collection: collection })">
				<p class="video-title">{{ subCollection.name }}</p>
				<div class="video-move-buttons" v-if="isAdmin">
					<button class="material-icons" @click.stop="prevSub(index)">arrow_back</button>
					<button class="material-icons" @click.stop="nextSub(index)">arrow_forward</button>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase.js'
import { Chrome } from 'vue-color'
// import firebase from 'firebase/app'

export default {
	components: {
		Chrome
	},
    data() {
		return {
			videoScrollAmount: 0,
			isEditing: false,
			nameEdit: "",
			accessVal: "",
			colour: "#00a7e5",
			showPicker: false
		}
	},
	methods: {
		subCollectionsForCollection() {
			return this.collection.subCollections.map(x => {
				var subCollection = this.subCollections.find(s => s.id === x)

				if (typeof subCollection == 'undefined') {
					console.log(`Can't find subcollection ${x}`)

				// 	db.collection("resourceCollections").doc(this.collection.id).update({
				// 		subCollections: firebase.firestore.FieldValue.arrayRemove(x)
				// 	})
				}
				
				return subCollection
			}).filter(x => typeof x != 'undefined')
		},
		edit() {
			if (this.isEditing) {
				db.collection('resourceCollections').doc(this.collection.id).update({
					name: this.nameEdit
				}).then(() => {
					this.$toast.open("Saved");
				});
			}

			this.isEditing = !this.isEditing
		},
		pickColour() {
			this.showPicker = !this.showPicker;

			if (!this.showPicker) {
				db.collection('resourceCollections').doc(this.collection.id).update({
					colour: this.colour
				}).then(() => {
					this.$toast.open("Saved");
				});
			}
		},
		changeColour(value) {
			value = value.hex;

			this.colour = value;
		},
		add() {
			this.$emit("add", this.collection.id);
		},
		remove() {
			this.$emit("remove", this.collection.id);
		},
		prev() {
			this.$emit('prev', this.collection.id);
		},
		next() {
			this.$emit('next', this.collection.id);
		},
		prevSub(index) {
			if (index == 0) {
				return;
			}

			var currentArray = this.collection.subCollections

			var indexEl = currentArray[index]
			currentArray[index] = currentArray[index - 1]
			currentArray[index - 1] = indexEl

			db.collection('resourceCollections').doc(this.collection.id).update({
				subCollections: currentArray
			}).then(() => {
				this.$emit('refresh')
				this.$forceUpdate()
			})
		},
		nextSub(index) {
			var currentArray = this.collection.subCollections

			if (index == currentArray.length - 1) {
				return;
			}

			var indexEl = currentArray[index]
			currentArray[index] = currentArray[index + 1]
			currentArray[index + 1] = indexEl

			db.collection('resourceCollections').doc(this.collection.id).update({
				subCollections: currentArray
			}).then(() => {
				this.$emit('refresh')
				this.$forceUpdate()
			})
		}
    },
    props: [
		'subCollections',
		'isAdmin',
		'collection',
		'collectionIndex'
	],
	mounted() {
		if (this.collection.colour) {
			this.colour = this.collection.colour;
		}

		this.nameEdit = this.collection.name;
	},
	watch: {
		collection: function(val) {
			if (val.colour) {
				this.colour = val.colour;
			}

			this.nameEdit = val.name;
		}
	}
}
</script>